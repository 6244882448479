import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import ConfigProvider, { ConfigContext } from '../config-provider';
/* istanbul ignore next */
export default function genPurePanel(Component, defaultPrefixCls, getDropdownCls) {
    return function PurePanel(props) {
        const { prefixCls: customizePrefixCls, style } = props;
        const holderRef = React.useRef(null);
        const [popupHeight, setPopupHeight] = React.useState(0);
        const [popupWidth, setPopupWidth] = React.useState(0);
        const [open, setOpen] = useMergedState(false, {
            value: props.open,
        });
        const { getPrefixCls } = React.useContext(ConfigContext);
        const prefixCls = getPrefixCls(defaultPrefixCls || 'select', customizePrefixCls);
        React.useEffect(() => {
            // We do not care about ssr
            setOpen(true);
            if (typeof ResizeObserver !== 'undefined') {
                const resizeObserver = new ResizeObserver((entries) => {
                    const element = entries[0].target;
                    setPopupHeight(element.offsetHeight + 8);
                    setPopupWidth(element.offsetWidth);
                });
                const interval = setInterval(() => {
                    var _a;
                    const dropdownCls = getDropdownCls
                        ? `.${getDropdownCls(prefixCls)}`
                        : `.${prefixCls}-dropdown`;
                    const popup = (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(dropdownCls);
                    if (popup) {
                        clearInterval(interval);
                        resizeObserver.observe(popup);
                    }
                }, 10);
                return () => {
                    clearInterval(interval);
                    resizeObserver.disconnect();
                };
            }
        }, []);
        return (React.createElement(ConfigProvider, { theme: {
                token: {
                    motionDurationFast: '0.01s',
                    motionDurationMid: '0.01s',
                    motionDurationSlow: '0.01s',
                },
            } },
            React.createElement("div", { ref: holderRef, style: {
                    paddingBottom: popupHeight,
                    position: 'relative',
                    width: 'fit-content',
                    minWidth: popupWidth,
                } },
                React.createElement(Component, Object.assign({}, props, { style: Object.assign(Object.assign({}, style), { margin: 0 }), open: open, visible: open, getPopupContainer: () => holderRef.current })))));
    };
}
