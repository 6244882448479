var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import toArray from 'rc-util/lib/Children/toArray';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import { cloneElement } from '../_util/reactNode';
import useResponsiveObserver, { responsiveArray } from '../_util/responsiveObserver';
import warning from '../_util/warning';
import DescriptionsItem from './Item';
import Row from './Row';
import useStyle from './style';
export const DescriptionsContext = React.createContext({});
const DEFAULT_COLUMN_MAP = {
    xxl: 3,
    xl: 3,
    lg: 3,
    md: 3,
    sm: 2,
    xs: 1,
};
function getColumn(column, screens) {
    if (typeof column === 'number') {
        return column;
    }
    if (typeof column === 'object') {
        for (let i = 0; i < responsiveArray.length; i++) {
            const breakpoint = responsiveArray[i];
            if (screens[breakpoint] && column[breakpoint] !== undefined) {
                return column[breakpoint] || DEFAULT_COLUMN_MAP[breakpoint];
            }
        }
    }
    return 3;
}
function getFilledItem(node, rowRestCol, span) {
    let clone = node;
    if (span === undefined || span > rowRestCol) {
        clone = cloneElement(node, {
            span: rowRestCol,
        });
        warning(span === undefined, 'Descriptions', 'Sum of column `span` in a line not match `column` of Descriptions.');
    }
    return clone;
}
function getRows(children, column) {
    const childNodes = toArray(children).filter((n) => n);
    const rows = [];
    let tmpRow = [];
    let rowRestCol = column;
    childNodes.forEach((node, index) => {
        var _a;
        const span = (_a = node.props) === null || _a === void 0 ? void 0 : _a.span;
        const mergedSpan = span || 1;
        // Additional handle last one
        if (index === childNodes.length - 1) {
            tmpRow.push(getFilledItem(node, rowRestCol, span));
            rows.push(tmpRow);
            return;
        }
        if (mergedSpan < rowRestCol) {
            rowRestCol -= mergedSpan;
            tmpRow.push(node);
        }
        else {
            tmpRow.push(getFilledItem(node, rowRestCol, mergedSpan));
            rows.push(tmpRow);
            rowRestCol = column;
            tmpRow = [];
        }
    });
    return rows;
}
function Descriptions(_a) {
    var { prefixCls: customizePrefixCls, title, extra, column = DEFAULT_COLUMN_MAP, colon = true, bordered, layout, children, className, rootClassName, style, size, labelStyle, contentStyle } = _a, restProps = __rest(_a, ["prefixCls", "title", "extra", "column", "colon", "bordered", "layout", "children", "className", "rootClassName", "style", "size", "labelStyle", "contentStyle"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('descriptions', customizePrefixCls);
    const [screens, setScreens] = React.useState({});
    const mergedColumn = getColumn(column, screens);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const responsiveObserver = useResponsiveObserver();
    // Responsive
    React.useEffect(() => {
        const token = responsiveObserver.subscribe((newScreens) => {
            if (typeof column !== 'object') {
                return;
            }
            setScreens(newScreens);
        });
        return () => {
            responsiveObserver.unsubscribe(token);
        };
    }, []);
    // Children
    const rows = getRows(children, mergedColumn);
    const contextValue = React.useMemo(() => ({ labelStyle, contentStyle }), [labelStyle, contentStyle]);
    return wrapSSR(React.createElement(DescriptionsContext.Provider, { value: contextValue },
        React.createElement("div", Object.assign({ className: classNames(prefixCls, {
                [`${prefixCls}-${size}`]: size && size !== 'default',
                [`${prefixCls}-bordered`]: !!bordered,
                [`${prefixCls}-rtl`]: direction === 'rtl',
            }, className, rootClassName, hashId), style: style }, restProps),
            (title || extra) && (React.createElement("div", { className: `${prefixCls}-header` },
                title && React.createElement("div", { className: `${prefixCls}-title` }, title),
                extra && React.createElement("div", { className: `${prefixCls}-extra` }, extra))),
            React.createElement("div", { className: `${prefixCls}-view` },
                React.createElement("table", null,
                    React.createElement("tbody", null, rows.map((row, index) => (React.createElement(Row, { key: index, index: index, colon: colon, prefixCls: prefixCls, vertical: layout === 'vertical', bordered: bordered, row: row })))))))));
}
if (process.env.NODE_ENV !== 'production') {
    Descriptions.displayName = 'Descriptions';
}
Descriptions.Item = DescriptionsItem;
export default Descriptions;
