var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { List } from 'rc-field-form';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import warning from '../_util/warning';
import { FormItemPrefixContext } from './context';
const FormList = (_a) => {
    var { prefixCls: customizePrefixCls, children } = _a, props = __rest(_a, ["prefixCls", "children"]);
    warning(!!props.name, 'Form.List', 'Miss `name` prop.');
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('form', customizePrefixCls);
    const contextValue = React.useMemo(() => ({
        prefixCls,
        status: 'error',
    }), [prefixCls]);
    return (React.createElement(List, Object.assign({}, props), (fields, operation, meta) => (React.createElement(FormItemPrefixContext.Provider, { value: contextValue }, children(fields.map((field) => (Object.assign(Object.assign({}, field), { fieldKey: field.key }))), operation, {
        errors: meta.errors,
        warnings: meta.warnings,
    })))));
};
export default FormList;
