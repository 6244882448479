(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("dayjs"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "dayjs"], factory);
	else if(typeof exports === 'object')
		exports["antd"] = factory(require("react"), require("react-dom"), require("dayjs"));
	else
		root["antd"] = factory(root["React"], root["ReactDOM"], root["dayjs"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__31__) {
return 