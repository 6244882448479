import { genComponentStyleHook, mergeToken } from '../../theme/internal';
import { genFocusStyle, resetComponent } from '../../style';
const genBreadcrumbStyle = (token) => {
    const { componentCls, iconCls } = token;
    return {
        [componentCls]: Object.assign(Object.assign({}, resetComponent(token)), { color: token.breadcrumbBaseColor, fontSize: token.breadcrumbFontSize, [iconCls]: {
                fontSize: token.breadcrumbIconFontSize,
            }, ol: {
                display: 'flex',
                flexWrap: 'wrap',
                margin: 0,
                padding: 0,
                listStyle: 'none',
            }, a: Object.assign({ color: token.breadcrumbLinkColor, transition: `color ${token.motionDurationMid}`, padding: `0 ${token.paddingXXS}px`, borderRadius: token.borderRadiusSM, height: token.lineHeight * token.fontSize, display: 'inline-block', marginInline: -token.marginXXS, '&:hover': {
                    color: token.breadcrumbLinkColorHover,
                    backgroundColor: token.colorBgTextHover,
                } }, genFocusStyle(token)), [`li:last-child`]: {
                color: token.breadcrumbLastItemColor,
                [`& > ${componentCls}-separator`]: {
                    display: 'none',
                },
            }, [`${componentCls}-separator`]: {
                marginInline: token.breadcrumbSeparatorMargin,
                color: token.breadcrumbSeparatorColor,
            }, [`${componentCls}-link`]: {
                [`
          > ${iconCls} + span,
          > ${iconCls} + a
        `]: {
                    marginInlineStart: token.marginXXS,
                },
            }, [`${componentCls}-overlay-link`]: {
                borderRadius: token.borderRadiusSM,
                height: token.lineHeight * token.fontSize,
                display: 'inline-block',
                padding: `0 ${token.paddingXXS}px`,
                marginInline: -token.marginXXS,
                [`> ${iconCls}`]: {
                    marginInlineStart: token.marginXXS,
                    fontSize: token.fontSizeIcon,
                },
                '&:hover': {
                    color: token.breadcrumbLinkColorHover,
                    backgroundColor: token.colorBgTextHover,
                    a: {
                        color: token.breadcrumbLinkColorHover,
                    },
                },
                a: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
            }, 
            // rtl style
            [`&${token.componentCls}-rtl`]: {
                direction: 'rtl',
            } }),
    };
};
// ============================== Export ==============================
export default genComponentStyleHook('Breadcrumb', (token) => {
    const BreadcrumbToken = mergeToken(token, {
        breadcrumbBaseColor: token.colorTextDescription,
        breadcrumbFontSize: token.fontSize,
        breadcrumbIconFontSize: token.fontSize,
        breadcrumbLinkColor: token.colorTextDescription,
        breadcrumbLinkColorHover: token.colorText,
        breadcrumbLastItemColor: token.colorText,
        breadcrumbSeparatorMargin: token.marginXS,
        breadcrumbSeparatorColor: token.colorTextDescription,
    });
    return [genBreadcrumbStyle(BreadcrumbToken)];
});
