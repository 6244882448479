import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import CSSMotion from 'rc-motion';
import React from 'react';
const getCollapsedWidth = () => ({
    width: 0,
    opacity: 0,
    transform: 'scale(0)',
});
const getRealWidth = (node) => ({
    width: node.scrollWidth,
    opacity: 1,
    transform: 'scale(1)',
});
const LoadingIcon = ({ prefixCls, loading, existIcon }) => {
    const visible = !!loading;
    if (existIcon) {
        return (React.createElement("span", { className: `${prefixCls}-loading-icon` },
            React.createElement(LoadingOutlined, null)));
    }
    return (React.createElement(CSSMotion, { visible: visible, 
        // We do not really use this motionName
        motionName: `${prefixCls}-loading-icon-motion`, removeOnLeave: true, onAppearStart: getCollapsedWidth, onAppearActive: getRealWidth, onEnterStart: getCollapsedWidth, onEnterActive: getRealWidth, onLeaveStart: getRealWidth, onLeaveActive: getCollapsedWidth }, ({ className, style }, ref) => (React.createElement("span", { className: `${prefixCls}-loading-icon`, style: style, ref: ref },
        React.createElement(LoadingOutlined, { className: className })))));
};
export default LoadingIcon;
