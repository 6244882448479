var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import RotateLeftOutlined from '@ant-design/icons/RotateLeftOutlined';
import RotateRightOutlined from '@ant-design/icons/RotateRightOutlined';
import ZoomInOutlined from '@ant-design/icons/ZoomInOutlined';
import ZoomOutOutlined from '@ant-design/icons/ZoomOutOutlined';
import RcImage from 'rc-image';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import { getTransitionName } from '../_util/motion';
// CSSINJS
import useStyle from './style';
export const icons = {
    rotateLeft: React.createElement(RotateLeftOutlined, null),
    rotateRight: React.createElement(RotateRightOutlined, null),
    zoomIn: React.createElement(ZoomInOutlined, null),
    zoomOut: React.createElement(ZoomOutOutlined, null),
    close: React.createElement(CloseOutlined, null),
    left: React.createElement(LeftOutlined, null),
    right: React.createElement(RightOutlined, null),
};
const InternalPreviewGroup = (_a) => {
    var { previewPrefixCls: customizePrefixCls, preview } = _a, props = __rest(_a, ["previewPrefixCls", "preview"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('image', customizePrefixCls);
    const previewPrefixCls = `${prefixCls}-preview`;
    const rootPrefixCls = getPrefixCls();
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const mergedPreview = React.useMemo(() => {
        if (preview === false) {
            return preview;
        }
        const _preview = typeof preview === 'object' ? preview : {};
        return Object.assign(Object.assign({}, _preview), { transitionName: getTransitionName(rootPrefixCls, 'zoom', _preview.transitionName), maskTransitionName: getTransitionName(rootPrefixCls, 'fade', _preview.maskTransitionName), rootClassName: hashId });
    }, [preview]);
    return wrapSSR(React.createElement(RcImage.PreviewGroup, Object.assign({ preview: mergedPreview, previewPrefixCls: previewPrefixCls, icons: icons }, props)));
};
export default InternalPreviewGroup;
