import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Skeleton from '../skeleton';
import StatisticNumber from './Number';
import useStyle from './style';
import Countdown from './Countdown';
const Statistic = (props) => {
    const { prefixCls: customizePrefixCls, className, rootClassName, style, valueStyle, value = 0, title, valueRender, prefix, suffix, loading = false, onMouseEnter, onMouseLeave, decimalSeparator = '.', groupSeparator = ',', } = props;
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('statistic', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const valueNode = (React.createElement(StatisticNumber, Object.assign({ decimalSeparator: decimalSeparator, groupSeparator: groupSeparator, prefixCls: prefixCls }, props, { value: value })));
    const cls = classNames(prefixCls, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className, rootClassName, hashId);
    return wrapSSR(React.createElement("div", { className: cls, style: style, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave },
        title && React.createElement("div", { className: `${prefixCls}-title` }, title),
        React.createElement(Skeleton, { paragraph: false, loading: loading, className: `${prefixCls}-skeleton` },
            React.createElement("div", { style: valueStyle, className: `${prefixCls}-content` },
                prefix && React.createElement("span", { className: `${prefixCls}-content-prefix` }, prefix),
                valueRender ? valueRender(valueNode) : valueNode,
                suffix && React.createElement("span", { className: `${prefixCls}-content-suffix` }, suffix)))));
};
if (process.env.NODE_ENV !== 'production') {
    Statistic.displayName = 'Statistic';
}
Statistic.Countdown = Countdown;
export default Statistic;
