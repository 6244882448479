import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
import getDataOrAriaProps from '../_util/getDataOrAriaProps';
import { RadioGroupContextProvider } from './context';
import Radio from './radio';
import useStyle from './style';
const RadioGroup = React.forwardRef((props, ref) => {
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const [value, setValue] = useMergedState(props.defaultValue, {
        value: props.value,
    });
    const onRadioChange = (ev) => {
        const lastValue = value;
        const val = ev.target.value;
        if (!('value' in props)) {
            setValue(val);
        }
        const { onChange } = props;
        if (onChange && val !== lastValue) {
            onChange(ev);
        }
    };
    const { prefixCls: customizePrefixCls, className, rootClassName, options, buttonStyle = 'outline', disabled, children, size: customizeSize, style, id, onMouseEnter, onMouseLeave, onFocus, onBlur, } = props;
    const prefixCls = getPrefixCls('radio', customizePrefixCls);
    const groupPrefixCls = `${prefixCls}-group`;
    // Style
    const [wrapSSR, hashId] = useStyle(prefixCls);
    let childrenToRender = children;
    // 如果存在 options, 优先使用
    if (options && options.length > 0) {
        childrenToRender = options.map((option) => {
            if (typeof option === 'string' || typeof option === 'number') {
                // 此处类型自动推导为 string
                return (React.createElement(Radio, { key: option.toString(), prefixCls: prefixCls, disabled: disabled, value: option, checked: value === option }, option));
            }
            // 此处类型自动推导为 { label: string value: string }
            return (React.createElement(Radio, { key: `radio-group-value-options-${option.value}`, prefixCls: prefixCls, disabled: option.disabled || disabled, value: option.value, checked: value === option.value, style: option.style }, option.label));
        });
    }
    const mergedSize = customizeSize || size;
    const classString = classNames(groupPrefixCls, `${groupPrefixCls}-${buttonStyle}`, {
        [`${groupPrefixCls}-${mergedSize}`]: mergedSize,
        [`${groupPrefixCls}-rtl`]: direction === 'rtl',
    }, className, rootClassName, hashId);
    return wrapSSR(React.createElement("div", Object.assign({}, getDataOrAriaProps(props), { className: classString, style: style, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onFocus: onFocus, onBlur: onBlur, id: id, ref: ref }),
        React.createElement(RadioGroupContextProvider, { value: {
                onChange: onRadioChange,
                value,
                disabled: props.disabled,
                name: props.name,
                optionType: props.optionType,
            } }, childrenToRender)));
});
export default React.memo(RadioGroup);
