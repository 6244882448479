import * as React from 'react';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import classNames from 'classnames';
export default function DrawerPanel(props) {
    const { prefixCls, title, footer, extra, closable = true, closeIcon = React.createElement(CloseOutlined, null), onClose, headerStyle, drawerStyle, bodyStyle, footerStyle, children, } = props;
    const closeIconNode = closable && (React.createElement("button", { type: "button", onClick: onClose, "aria-label": "Close", className: `${prefixCls}-close` }, closeIcon));
    function renderHeader() {
        if (!title && !closable) {
            return null;
        }
        return (React.createElement("div", { className: classNames(`${prefixCls}-header`, {
                [`${prefixCls}-header-close-only`]: closable && !title && !extra,
            }), style: headerStyle },
            React.createElement("div", { className: `${prefixCls}-header-title` },
                closeIconNode,
                title && React.createElement("div", { className: `${prefixCls}-title` }, title)),
            extra && React.createElement("div", { className: `${prefixCls}-extra` }, extra)));
    }
    function renderFooter() {
        if (!footer) {
            return null;
        }
        const footerClassName = `${prefixCls}-footer`;
        return (React.createElement("div", { className: footerClassName, style: footerStyle }, footer));
    }
    return (React.createElement("div", { className: `${prefixCls}-wrapper-body`, style: Object.assign({}, drawerStyle) },
        renderHeader(),
        React.createElement("div", { className: `${prefixCls}-body`, style: bodyStyle }, children),
        renderFooter()));
}
