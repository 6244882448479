import { genComponentStyleHook, mergeToken } from '../../theme/internal';
import { resetComponent, textEllipsis } from '../../style';
const genBorderedStyle = (token) => {
    const { componentCls, descriptionsSmallPadding, descriptionsDefaultPadding, descriptionsMiddlePadding, descriptionsBg, } = token;
    return {
        [`&${componentCls}-bordered`]: {
            [`${componentCls}-view`]: {
                border: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
                '> table': {
                    tableLayout: 'auto',
                    borderCollapse: 'collapse',
                },
            },
            [`${componentCls}-item-label, ${componentCls}-item-content`]: {
                padding: descriptionsDefaultPadding,
                borderInlineEnd: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
                '&:last-child': {
                    borderInlineEnd: 'none',
                },
            },
            [`${componentCls}-item-label`]: {
                color: token.colorTextSecondary,
                backgroundColor: descriptionsBg,
                '&::after': {
                    display: 'none',
                },
            },
            [`${componentCls}-row`]: {
                borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
                '&:last-child': {
                    borderBottom: 'none',
                },
            },
            [`&${componentCls}-middle`]: {
                [`${componentCls}-item-label, ${componentCls}-item-content`]: {
                    padding: descriptionsMiddlePadding,
                },
            },
            [`&${componentCls}-small`]: {
                [`${componentCls}-item-label, ${componentCls}-item-content`]: {
                    padding: descriptionsSmallPadding,
                },
            },
        },
    };
};
const genDescriptionStyles = (token) => {
    const { componentCls, descriptionsExtraColor, descriptionItemPaddingBottom, descriptionsItemLabelColonMarginRight, descriptionsItemLabelColonMarginLeft, descriptionsTitleMarginBottom, } = token;
    return {
        [componentCls]: Object.assign(Object.assign(Object.assign({}, resetComponent(token)), genBorderedStyle(token)), { [`&-rtl`]: {
                direction: 'rtl',
            }, [`${componentCls}-header`]: {
                display: 'flex',
                alignItems: 'center',
                marginBottom: descriptionsTitleMarginBottom,
            }, [`${componentCls}-title`]: Object.assign(Object.assign({}, textEllipsis), { flex: 'auto', color: token.colorText, fontWeight: token.fontWeightStrong, fontSize: token.fontSizeLG, lineHeight: token.lineHeightLG }), [`${componentCls}-extra`]: {
                marginInlineStart: 'auto',
                color: descriptionsExtraColor,
                fontSize: token.fontSize,
            }, [`${componentCls}-view`]: {
                width: '100%',
                borderRadius: token.borderRadiusLG,
                table: {
                    width: '100%',
                    tableLayout: 'fixed',
                },
            }, [`${componentCls}-row`]: {
                '> th, > td': {
                    paddingBottom: descriptionItemPaddingBottom,
                },
                '&:last-child': {
                    borderBottom: 'none',
                },
            }, [`${componentCls}-item-label`]: {
                color: token.colorTextTertiary,
                fontWeight: 'normal',
                fontSize: token.fontSize,
                lineHeight: token.lineHeight,
                textAlign: `start`,
                '&::after': {
                    content: '":"',
                    position: 'relative',
                    top: -0.5,
                    marginInline: `${descriptionsItemLabelColonMarginLeft}px ${descriptionsItemLabelColonMarginRight}px`,
                },
                [`&${componentCls}-item-no-colon::after`]: {
                    content: '""',
                },
            }, [`${componentCls}-item-no-label`]: {
                '&::after': {
                    margin: 0,
                    content: '""',
                },
            }, [`${componentCls}-item-content`]: {
                display: 'table-cell',
                flex: 1,
                color: token.colorText,
                fontSize: token.fontSize,
                lineHeight: token.lineHeight,
                wordBreak: 'break-word',
                overflowWrap: 'break-word',
            }, [`${componentCls}-item`]: {
                paddingBottom: 0,
                verticalAlign: 'top',
                '&-container': {
                    display: 'flex',
                    [`${componentCls}-item-label`]: {
                        display: 'inline-flex',
                        alignItems: 'baseline',
                    },
                    [`${componentCls}-item-content`]: {
                        display: 'inline-flex',
                        alignItems: 'baseline',
                    },
                },
            }, '&-middle': {
                [`${componentCls}-row`]: {
                    '> th, > td': {
                        paddingBottom: token.paddingSM,
                    },
                },
            }, '&-small': {
                [`${componentCls}-row`]: {
                    '> th, > td': {
                        paddingBottom: token.paddingXS,
                    },
                },
            } }),
    };
};
// ============================== Export ==============================
export default genComponentStyleHook('Descriptions', (token) => {
    const descriptionsBg = token.colorFillAlter;
    const descriptionsTitleMarginBottom = token.fontSizeSM * token.lineHeightSM;
    const descriptionsExtraColor = token.colorText;
    const descriptionsSmallPadding = `${token.paddingXS}px ${token.padding}px`;
    const descriptionsDefaultPadding = `${token.padding}px ${token.paddingLG}px`;
    const descriptionsMiddlePadding = `${token.paddingSM}px ${token.paddingLG}px`;
    const descriptionItemPaddingBottom = token.padding;
    const descriptionsItemLabelColonMarginRight = token.marginXS;
    const descriptionsItemLabelColonMarginLeft = token.marginXXS / 2;
    const descriptionToken = mergeToken(token, {
        descriptionsBg,
        descriptionsTitleMarginBottom,
        descriptionsExtraColor,
        descriptionItemPaddingBottom,
        descriptionsSmallPadding,
        descriptionsDefaultPadding,
        descriptionsMiddlePadding,
        descriptionsItemLabelColonMarginRight,
        descriptionsItemLabelColonMarginLeft,
    });
    return [genDescriptionStyles(descriptionToken)];
});
