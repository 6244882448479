var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// TODO: 4.0 - codemod should help to change `filterOption` to support node props.
import classNames from 'classnames';
import RcSelect, { OptGroup, Option } from 'rc-select';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import DefaultRenderEmpty from '../config-provider/defaultRenderEmpty';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import { FormItemInputContext } from '../form/context';
import { getTransitionDirection, getTransitionName } from '../_util/motion';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import getIcons from './utils/iconUtil';
import { useCompactItemContext } from '../space/Compact';
import genPurePanel from '../_util/PurePanel';
import warning from '../_util/warning';
import useStyle from './style';
const SECRET_COMBOBOX_MODE_DO_NOT_USE = 'SECRET_COMBOBOX_MODE_DO_NOT_USE';
const InternalSelect = (_a, ref) => {
    var { prefixCls: customizePrefixCls, bordered = true, className, rootClassName, getPopupContainer, popupClassName, dropdownClassName, listHeight = 256, placement, listItemHeight = 24, size: customizeSize, disabled: customDisabled, notFoundContent, status: customStatus, showArrow } = _a, props = __rest(_a, ["prefixCls", "bordered", "className", "rootClassName", "getPopupContainer", "popupClassName", "dropdownClassName", "listHeight", "placement", "listItemHeight", "size", "disabled", "notFoundContent", "status", "showArrow"]);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, select, } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const mode = React.useMemo(() => {
        const { mode: m } = props;
        if (m === 'combobox') {
            return undefined;
        }
        if (m === SECRET_COMBOBOX_MODE_DO_NOT_USE) {
            return 'combobox';
        }
        return m;
    }, [props.mode]);
    const isMultiple = mode === 'multiple' || mode === 'tags';
    const mergedShowArrow = showArrow !== undefined ? showArrow : props.loading || !(isMultiple || mode === 'combobox');
    // ===================== Form Status =====================
    const { status: contextStatus, hasFeedback, isFormItemInput, feedbackIcon, } = React.useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Empty =====================
    let mergedNotFound;
    if (notFoundContent !== undefined) {
        mergedNotFound = notFoundContent;
    }
    else if (mode === 'combobox') {
        mergedNotFound = null;
    }
    else {
        mergedNotFound = (renderEmpty === null || renderEmpty === void 0 ? void 0 : renderEmpty('Select')) || React.createElement(DefaultRenderEmpty, { componentName: "Select" });
    }
    // ===================== Icons =====================
    const { suffixIcon, itemIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple: isMultiple, hasFeedback,
        feedbackIcon, showArrow: mergedShowArrow, prefixCls }));
    const selectProps = omit(props, ['suffixIcon', 'itemIcon']);
    const rcSelectRtlDropdownClassName = classNames(popupClassName || dropdownClassName, {
        [`${prefixCls}-dropdown-${direction}`]: direction === 'rtl',
    }, rootClassName, hashId);
    const mergedSize = compactSize || customizeSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    const mergedClassName = classNames({
        [`${prefixCls}-lg`]: mergedSize === 'large',
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-in-form-item`]: isFormItemInput,
    }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), compactItemClassnames, className, rootClassName, hashId);
    // ===================== Placement =====================
    const getPlacement = () => {
        if (placement !== undefined) {
            return placement;
        }
        return direction === 'rtl' ? 'bottomRight' : 'bottomLeft';
    };
    // ====================== Warning ======================
    if (process.env.NODE_ENV !== 'production') {
        warning(!dropdownClassName, 'Select', '`dropdownClassName` is deprecated. Please use `popupClassName` instead.');
    }
    // ====================== Render =======================
    return wrapSSR(React.createElement(RcSelect, Object.assign({ ref: ref, virtual: virtual, dropdownMatchSelectWidth: dropdownMatchSelectWidth, showSearch: select === null || select === void 0 ? void 0 : select.showSearch }, selectProps, { transitionName: getTransitionName(rootPrefixCls, getTransitionDirection(placement), props.transitionName), listHeight: listHeight, listItemHeight: listItemHeight, mode: mode, prefixCls: prefixCls, placement: getPlacement(), direction: direction, inputIcon: suffixIcon, menuItemSelectedIcon: itemIcon, removeIcon: removeIcon, clearIcon: clearIcon, notFoundContent: mergedNotFound, className: mergedClassName, getPopupContainer: getPopupContainer || getContextPopupContainer, dropdownClassName: rcSelectRtlDropdownClassName, showArrow: hasFeedback || showArrow, disabled: mergedDisabled })));
};
if (process.env.NODE_ENV !== 'production') {
    InternalSelect.displayName = 'Select';
}
const Select = React.forwardRef(InternalSelect);
// We don't care debug panel
/* istanbul ignore next */
const PurePanel = genPurePanel(Select);
Select.SECRET_COMBOBOX_MODE_DO_NOT_USE = SECRET_COMBOBOX_MODE_DO_NOT_USE;
Select.Option = Option;
Select.OptGroup = OptGroup;
Select._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default Select;
