var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import useLocale from '../locale/useLocale';
import DefaultEmptyImg from './empty';
import SimpleEmptyImg from './simple';
import useStyle from './style';
const defaultEmptyImg = React.createElement(DefaultEmptyImg, null);
const simpleEmptyImg = React.createElement(SimpleEmptyImg, null);
const Empty = (_a) => {
    var { className, rootClassName, prefixCls: customizePrefixCls, image = defaultEmptyImg, description, children, imageStyle } = _a, restProps = __rest(_a, ["className", "rootClassName", "prefixCls", "image", "description", "children", "imageStyle"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('empty', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const [locale] = useLocale('Empty');
    const des = typeof description !== 'undefined' ? description : locale === null || locale === void 0 ? void 0 : locale.description;
    const alt = typeof des === 'string' ? des : 'empty';
    let imageNode = null;
    if (typeof image === 'string') {
        imageNode = React.createElement("img", { alt: alt, src: image });
    }
    else {
        imageNode = image;
    }
    return wrapSSR(React.createElement("div", Object.assign({ className: classNames(hashId, prefixCls, {
            [`${prefixCls}-normal`]: image === simpleEmptyImg,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className, rootClassName) }, restProps),
        React.createElement("div", { className: `${prefixCls}-image`, style: imageStyle }, imageNode),
        des && React.createElement("div", { className: `${prefixCls}-description` }, des),
        children && React.createElement("div", { className: `${prefixCls}-footer` }, children)));
};
Empty.PRESENTED_IMAGE_DEFAULT = defaultEmptyImg;
Empty.PRESENTED_IMAGE_SIMPLE = simpleEmptyImg;
if (process.env.NODE_ENV !== 'production') {
    Empty.displayName = 'Empty';
}
export default Empty;
