import { render } from 'rc-util/lib/React/render';
import * as React from 'react';
import ConfigProvider, { globalConfig } from '../config-provider';
import PurePanel from './PurePanel';
import useNotification, { useInternalNotification } from './useNotification';
let notification = null;
let act = (callback) => callback();
let taskQueue = [];
let defaultGlobalConfig = {};
function getGlobalContext() {
    const { prefixCls: globalPrefixCls, getContainer: globalGetContainer, rtl, maxCount, top, bottom, } = defaultGlobalConfig;
    const mergedPrefixCls = globalPrefixCls !== null && globalPrefixCls !== void 0 ? globalPrefixCls : globalConfig().getPrefixCls('notification');
    const mergedContainer = (globalGetContainer === null || globalGetContainer === void 0 ? void 0 : globalGetContainer()) || document.body;
    return {
        prefixCls: mergedPrefixCls,
        container: mergedContainer,
        rtl,
        maxCount,
        top,
        bottom,
    };
}
const GlobalHolder = React.forwardRef((_, ref) => {
    const [prefixCls, setPrefixCls] = React.useState();
    const [container, setContainer] = React.useState();
    const [maxCount, setMaxCount] = React.useState();
    const [rtl, setRTL] = React.useState();
    const [top, setTop] = React.useState();
    const [bottom, setBottom] = React.useState();
    const [api, holder] = useInternalNotification({
        prefixCls,
        getContainer: () => container,
        maxCount,
        rtl,
        top,
        bottom,
    });
    const global = globalConfig();
    const rootPrefixCls = global.getRootPrefixCls();
    const rootIconPrefixCls = global.getIconPrefixCls();
    const sync = () => {
        const { prefixCls: nextGlobalPrefixCls, container: nextGlobalContainer, maxCount: nextGlobalMaxCount, rtl: nextGlobalRTL, top: nextTop, bottom: nextBottom, } = getGlobalContext();
        setPrefixCls(nextGlobalPrefixCls);
        setContainer(nextGlobalContainer);
        setMaxCount(nextGlobalMaxCount);
        setRTL(nextGlobalRTL);
        setTop(nextTop);
        setBottom(nextBottom);
    };
    React.useEffect(sync, []);
    React.useImperativeHandle(ref, () => {
        const instance = Object.assign({}, api);
        Object.keys(instance).forEach((method) => {
            instance[method] = (...args) => {
                sync();
                return api[method](...args);
            };
        });
        return {
            instance,
            sync,
        };
    });
    return (React.createElement(ConfigProvider, { prefixCls: rootPrefixCls, iconPrefixCls: rootIconPrefixCls }, holder));
});
function flushNotice() {
    if (!notification) {
        const holderFragment = document.createDocumentFragment();
        const newNotification = {
            fragment: holderFragment,
        };
        notification = newNotification;
        // Delay render to avoid sync issue
        act(() => {
            render(React.createElement(GlobalHolder, { ref: (node) => {
                    const { instance, sync } = node || {};
                    Promise.resolve().then(() => {
                        if (!newNotification.instance && instance) {
                            newNotification.instance = instance;
                            newNotification.sync = sync;
                            flushNotice();
                        }
                    });
                } }), holderFragment);
        });
        return;
    }
    // Notification not ready
    if (!notification.instance) {
        return;
    }
    // >>> Execute task
    taskQueue.forEach((task) => {
        // eslint-disable-next-line default-case
        switch (task.type) {
            case 'open': {
                act(() => {
                    notification.instance.open(Object.assign(Object.assign({}, defaultGlobalConfig), task.config));
                });
                break;
            }
            case 'destroy':
                act(() => {
                    notification === null || notification === void 0 ? void 0 : notification.instance.destroy(task.key);
                });
                break;
        }
    });
    // Clean up
    taskQueue = [];
}
// ==============================================================================
// ==                                  Export                                  ==
// ==============================================================================
function setNotificationGlobalConfig(config) {
    defaultGlobalConfig = Object.assign(Object.assign({}, defaultGlobalConfig), config);
    // Trigger sync for it
    act(() => {
        var _a;
        (_a = notification === null || notification === void 0 ? void 0 : notification.sync) === null || _a === void 0 ? void 0 : _a.call(notification);
    });
}
function open(config) {
    taskQueue.push({
        type: 'open',
        config,
    });
    flushNotice();
}
function destroy(key) {
    taskQueue.push({
        type: 'destroy',
        key,
    });
    flushNotice();
}
const methods = ['success', 'info', 'warning', 'error'];
const baseStaticMethods = {
    open,
    destroy,
    config: setNotificationGlobalConfig,
    useNotification,
    _InternalPanelDoNotUseOrYouWillBeFired: PurePanel,
};
const staticMethods = baseStaticMethods;
methods.forEach((type) => {
    staticMethods[type] = (config) => open(Object.assign(Object.assign({}, config), { type }));
});
// ==============================================================================
// ==                                   Test                                   ==
// ==============================================================================
const noop = () => { };
/** @private Only Work in test env */
// eslint-disable-next-line import/no-mutable-exports
export let actWrapper = noop;
if (process.env.NODE_ENV === 'test') {
    actWrapper = (wrapper) => {
        act = wrapper;
    };
}
export default staticMethods;
