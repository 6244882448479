var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import { Popup } from 'rc-tooltip';
import { ConfigContext } from '../config-provider';
import useStyle from './style';
import { getRenderPropValue } from '../_util/getRenderPropValue';
export const getOverlay = (prefixCls, title, content) => {
    if (!title && !content)
        return undefined;
    return (React.createElement(React.Fragment, null,
        title && React.createElement("div", { className: `${prefixCls}-title` }, getRenderPropValue(title)),
        React.createElement("div", { className: `${prefixCls}-inner-content` }, getRenderPropValue(content))));
};
export function RawPurePanel(props) {
    const { hashId, prefixCls, className, style, placement = 'top', title, content, children, } = props;
    return (React.createElement("div", { className: classNames(hashId, prefixCls, `${prefixCls}-pure`, `${prefixCls}-placement-${placement}`, className), style: style },
        React.createElement(Popup, Object.assign({}, props, { className: hashId, prefixCls: prefixCls }), children || getOverlay(prefixCls, title, content))));
}
export default function PurePanel(props) {
    const { prefixCls: customizePrefixCls } = props, restProps = __rest(props, ["prefixCls"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    return wrapSSR(React.createElement(RawPurePanel, Object.assign({}, restProps, { prefixCls: prefixCls, hashId: hashId })));
}
