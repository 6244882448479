var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Tooltip from '../tooltip';
import { getRenderPropValue } from '../_util/getRenderPropValue';
import { getTransitionName } from '../_util/motion';
import PurePanel from './PurePanel';
// CSSINJS
import useStyle from './style';
const Overlay = ({ title, content, prefixCls }) => {
    if (!title && !content) {
        return null;
    }
    return (React.createElement(React.Fragment, null,
        title && React.createElement("div", { className: `${prefixCls}-title` }, getRenderPropValue(title)),
        React.createElement("div", { className: `${prefixCls}-inner-content` }, getRenderPropValue(content))));
};
const Popover = React.forwardRef((props, ref) => {
    var _a, _b;
    const { prefixCls: customizePrefixCls, title, content, overlayClassName, placement = 'top', trigger = 'hover', mouseEnterDelay = 0.1, mouseLeaveDelay = 0.1, overlayStyle = {}, arrowPointAtCenter, arrow } = props, otherProps = __rest(props, ["prefixCls", "title", "content", "overlayClassName", "placement", "trigger", "mouseEnterDelay", "mouseLeaveDelay", "overlayStyle", "arrowPointAtCenter", "arrow"]);
    const { getPrefixCls } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('popover', customizePrefixCls);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const rootPrefixCls = getPrefixCls();
    const overlayCls = classNames(overlayClassName, hashId);
    const mergedArrowPointAtCenter = (_b = (_a = (typeof arrow !== 'boolean' && (arrow === null || arrow === void 0 ? void 0 : arrow.arrowPointAtCenter))) !== null && _a !== void 0 ? _a : arrowPointAtCenter) !== null && _b !== void 0 ? _b : false;
    const mergedArrow = arrow !== null && arrow !== void 0 ? arrow : { arrowPointAtCenter: mergedArrowPointAtCenter };
    return wrapSSR(React.createElement(Tooltip, Object.assign({ placement: placement, arrow: mergedArrow, trigger: trigger, mouseEnterDelay: mouseEnterDelay, mouseLeaveDelay: mouseLeaveDelay, overlayStyle: overlayStyle }, otherProps, { prefixCls: prefixCls, overlayClassName: overlayCls, ref: ref, overlay: React.createElement(Overlay, { prefixCls: prefixCls, title: title, content: content }), transitionName: getTransitionName(rootPrefixCls, 'zoom-big', otherProps.transitionName), "data-popover-inject": true })));
});
if (process.env.NODE_ENV !== 'production') {
    Popover.displayName = 'Popover';
}
Popover._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default Popover;
