var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import classNames from 'classnames';
import RcTreeSelect, { SHOW_ALL, SHOW_CHILD, SHOW_PARENT, TreeNode } from 'rc-tree-select';
import omit from 'rc-util/lib/omit';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import DefaultRenderEmpty from '../config-provider/defaultRenderEmpty';
import DisabledContext from '../config-provider/DisabledContext';
import SizeContext from '../config-provider/SizeContext';
import { FormItemInputContext } from '../form/context';
import genPurePanel from '../_util/PurePanel';
import useSelectStyle from '../select/style';
import getIcons from '../select/utils/iconUtil';
import renderSwitcherIcon from '../tree/utils/iconUtil';
import { getTransitionDirection, getTransitionName } from '../_util/motion';
import { getMergedStatus, getStatusClassNames } from '../_util/statusUtils';
import { useCompactItemContext } from '../space/Compact';
import warning from '../_util/warning';
import useStyle from './style';
const InternalTreeSelect = (_a, ref) => {
    var { prefixCls: customizePrefixCls, size: customizeSize, disabled: customDisabled, bordered = true, className, rootClassName, treeCheckable, multiple, listHeight = 256, listItemHeight = 26, placement, notFoundContent, switcherIcon, treeLine, getPopupContainer, popupClassName, dropdownClassName, treeIcon = false, transitionName, choiceTransitionName = '', status: customStatus, showArrow, treeExpandAction } = _a, props = __rest(_a, ["prefixCls", "size", "disabled", "bordered", "className", "rootClassName", "treeCheckable", "multiple", "listHeight", "listItemHeight", "placement", "notFoundContent", "switcherIcon", "treeLine", "getPopupContainer", "popupClassName", "dropdownClassName", "treeIcon", "transitionName", "choiceTransitionName", "status", "showArrow", "treeExpandAction"]);
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, renderEmpty, direction, virtual, dropdownMatchSelectWidth, } = React.useContext(ConfigContext);
    const size = React.useContext(SizeContext);
    if (process.env.NODE_ENV !== 'production') {
        warning(multiple !== false || !treeCheckable, 'TreeSelect', '`multiple` will always be `true` when `treeCheckable` is true');
        warning(!dropdownClassName, 'TreeSelect', '`dropdownClassName` is deprecated. Please use `popupClassName` instead.');
    }
    const rootPrefixCls = getPrefixCls();
    const prefixCls = getPrefixCls('select', customizePrefixCls);
    const treePrefixCls = getPrefixCls('select-tree', customizePrefixCls);
    const treeSelectPrefixCls = getPrefixCls('tree-select', customizePrefixCls);
    const { compactSize, compactItemClassnames } = useCompactItemContext(prefixCls, direction);
    const [wrapSelectSSR, hashId] = useSelectStyle(prefixCls);
    const [wrapTreeSelectSSR] = useStyle(treeSelectPrefixCls, treePrefixCls);
    const mergedDropdownClassName = classNames(popupClassName || dropdownClassName, `${treeSelectPrefixCls}-dropdown`, {
        [`${treeSelectPrefixCls}-dropdown-rtl`]: direction === 'rtl',
    }, rootClassName, hashId);
    const isMultiple = !!(treeCheckable || multiple);
    const mergedShowArrow = showArrow !== undefined ? showArrow : props.loading || !isMultiple;
    // ===================== Form =====================
    const { status: contextStatus, hasFeedback, isFormItemInput, feedbackIcon, } = React.useContext(FormItemInputContext);
    const mergedStatus = getMergedStatus(contextStatus, customStatus);
    // ===================== Icons =====================
    const { suffixIcon, removeIcon, clearIcon } = getIcons(Object.assign(Object.assign({}, props), { multiple: isMultiple, showArrow: mergedShowArrow, hasFeedback,
        feedbackIcon,
        prefixCls }));
    // ===================== Empty =====================
    let mergedNotFound;
    if (notFoundContent !== undefined) {
        mergedNotFound = notFoundContent;
    }
    else {
        mergedNotFound = (renderEmpty === null || renderEmpty === void 0 ? void 0 : renderEmpty('Select')) || React.createElement(DefaultRenderEmpty, { componentName: "Select" });
    }
    // ==================== Render =====================
    const selectProps = omit(props, [
        'suffixIcon',
        'itemIcon',
        'removeIcon',
        'clearIcon',
        'switcherIcon',
    ]);
    // ===================== Placement =====================
    const memoizedPlacement = React.useMemo(() => {
        if (placement !== undefined) {
            return placement;
        }
        return direction === 'rtl' ? 'bottomRight' : 'bottomLeft';
    }, [placement, direction]);
    const mergedSize = compactSize || customizeSize || size;
    // ===================== Disabled =====================
    const disabled = React.useContext(DisabledContext);
    const mergedDisabled = customDisabled !== null && customDisabled !== void 0 ? customDisabled : disabled;
    const mergedClassName = classNames(!customizePrefixCls && treeSelectPrefixCls, {
        [`${prefixCls}-lg`]: mergedSize === 'large',
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-borderless`]: !bordered,
        [`${prefixCls}-in-form-item`]: isFormItemInput,
    }, getStatusClassNames(prefixCls, mergedStatus, hasFeedback), compactItemClassnames, className, rootClassName, hashId);
    const returnNode = (React.createElement(RcTreeSelect, Object.assign({ virtual: virtual, dropdownMatchSelectWidth: dropdownMatchSelectWidth, disabled: mergedDisabled }, selectProps, { ref: ref, prefixCls: prefixCls, className: mergedClassName, listHeight: listHeight, listItemHeight: listItemHeight, treeCheckable: treeCheckable ? React.createElement("span", { className: `${prefixCls}-tree-checkbox-inner` }) : treeCheckable, treeLine: !!treeLine, inputIcon: suffixIcon, multiple: multiple, placement: memoizedPlacement, removeIcon: removeIcon, clearIcon: clearIcon, switcherIcon: (nodeProps) => renderSwitcherIcon(treePrefixCls, switcherIcon, nodeProps, treeLine), showTreeIcon: treeIcon, notFoundContent: mergedNotFound, getPopupContainer: getPopupContainer || getContextPopupContainer, treeMotion: null, dropdownClassName: mergedDropdownClassName, choiceTransitionName: getTransitionName(rootPrefixCls, '', choiceTransitionName), transitionName: getTransitionName(rootPrefixCls, getTransitionDirection(placement), transitionName), showArrow: hasFeedback || showArrow, treeExpandAction: treeExpandAction })));
    return wrapSelectSSR(wrapTreeSelectSSR(returnNode));
};
const TreeSelectRef = React.forwardRef(InternalTreeSelect);
const TreeSelect = TreeSelectRef;
// We don't care debug panel
/* istanbul ignore next */
const PurePanel = genPurePanel(TreeSelect);
TreeSelect.TreeNode = TreeNode;
TreeSelect.SHOW_ALL = SHOW_ALL;
TreeSelect.SHOW_PARENT = SHOW_PARENT;
TreeSelect.SHOW_CHILD = SHOW_CHILD;
TreeSelect._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export { TreeNode };
export default TreeSelect;
